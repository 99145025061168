export const BASE_URL = process.env.REACT_APP_API_URL

export const URL = {
  LOGIN: `${BASE_URL}/onboard/sign-in`,
  REFRASH_TOKEN: `${BASE_URL}/onboard/token/refresh`,
  USER_UPDATE: `${BASE_URL}/onboard/user/update`,
  STORE_CREATE: `${BASE_URL}/onboard/store-create`,
  STORE_DELETE: `${BASE_URL}/onboard/store-delete`,
  STORE_SOFT_DELETE: `${BASE_URL}/onboard/soft-delete-store`,
  STORE_EDIT: `${BASE_URL}/onboard/store-edit`,
  STORE_ROLE_BACK: `${BASE_URL}/onboard/retrive-soft-delete-store`,
  STORE_LIST: `${BASE_URL}/onboard/store-list`,
  UPLOAD_IMAGE_BASE64: `${BASE_URL}/file/upload/base64`,
  UPLOAD_IMAGE_FILE: `${BASE_URL}/file/upload/file`,
  THEME_CATEGORY_LIST: `${BASE_URL}/onboard/store/category-list`,
  THEME_LIST: `${BASE_URL}/onboard/available/theme-list`,
  COURIER_REQUEST_LIST: `${BASE_URL}/onboard/shop-courier-configuration-list`,
  COURIER_CONFIG: `${BASE_URL}/onboard/add-courier-config`,
  GET_COURIER_CONFIG: `${BASE_URL}/onboard/get-courier-config`,
  GET_COMPONENTS: `${BASE_URL}/onboard/theme/component/list`,
  GET_LAYOUTS: `${BASE_URL}/onboard/theme/component/layout/list`,
  ADD_COMPONENT: `${BASE_URL}/onboard/theme/component/add`,
  DELETE_COMPONENT: `${BASE_URL}/onboard/theme/component/delete_component`,
  ADD_LAYOUT: `${BASE_URL}/onboard/theme/component/layout/add`,
  EDIT_LAYOUT: `${BASE_URL}/onboard/theme/component/layout/update`,
  EDIT_COMPONENT: `${BASE_URL}/onboard/theme/component/update`,
  EDIT_LAYOUT: `${BASE_URL}/onboard/theme/component/layout/update`,
}
