import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../_metronic/helpers'
import {URL} from '../../../../modules/helpers/api/action'
import {User, UsersQueryResponse} from './_models'

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${URL.COURIER_REQUEST_LIST}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const addConfig = (user: User): Promise<User | undefined> => {
  return axios
    .post(URL.COURIER_CONFIG, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

export {getUsers, addConfig}
