// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from './UserCustomHeader'
import {User} from '../../core/_models'
import {UserActionsCell} from './UserActionsCell'
import {dateReadable} from '../../../../../modules/helpers/mic'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Store Name' />,
    id: 'site_title',
    accessor: 'site_title',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Mobile' />,
    id: 'msisdn',
    accessor: 'msisdn',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Courier Partner Name' />,
    accessor: 'courier_partner_name',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Requested' />,
    id: 'createdat',
    Cell: ({...props}) => <>{dateReadable(props.data[props.row.index].createdat)}</>,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' />,
    id: 'status',
    Cell: ({...props}) => (
      <div
        className={`badge badge-${
          props.data[props.row.index].status === 'accepted' ? 'success' : 'danger'
        }`}
      >
        {props.data[props.row.index].status}
      </div>
    ),
    // accessor: 'status',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell data={props.data[props.row.index]} />,
  },
]

export {usersColumns}
