import clsx from 'clsx'
import {useFormik} from 'formik'
import {QueryClient} from 'react-query'
import Select from 'react-select'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useAddLayout} from '../../../../editor/api/useAddLayout'

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  slug: Yup.string().required('Slug is required'),
  image: Yup.string().required('Image path is required'),
  icon: Yup.string().required('Icon path is required'),
  component_id: Yup.string().required('Component ID is required'),
})

type Props = {
  componentList: any[]
}

const LayoutAddModal = ({componentList = []}: Props) => {
  const queryClient = new QueryClient()
  const defaultValue = {
    title: '',
    slug: '',
    image: '',
    icon: '',
    placement: '',
    component_id: '',
  }

  const {
    mutate: addLayoutMutate,
    isLoading: addLayoutLoading,
    data: addLayoutData,
    error: addLayoutErr,
  } = useAddLayout(
    (res: any) => {
      toast.success(res?.message || 'Layout Added', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    },
    (error: any) => {
      toast.error(error?.message || 'Error Occurred', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    },
    queryClient
  )

  const formik = useFormik({
    initialValues: defaultValue,
    validationSchema: schema,

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      addLayoutMutate(values)
    },
  })

  return (
    <div>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col'>
            <div className='row'>
              <div className='col'>
                <div className='mb-2'>
                  <label className='required fw-bold fs-6 me-2 min-w-60px'>Title</label>
                  <input
                    placeholder='Layout Title'
                    {...formik.getFieldProps('title')}
                    type='text'
                    name='title'
                    className={clsx(
                      'form-control mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.title && formik.errors.title},
                      {
                        'is-valid': formik.touched.title && !formik.errors.title,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col'>
                <div className='mb-2'>
                  <label className='required fw-bold fs-6 me-2 min-w-60px'>Slug</label>
                  <input
                    placeholder='Layout slug'
                    {...formik.getFieldProps('slug')}
                    type='text'
                    name='slug'
                    className={clsx(
                      'form-control mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.slug && formik.errors.slug},
                      {
                        'is-valid': formik.touched.slug && !formik.errors.slug,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.slug && formik.errors.slug && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.slug}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>Image</label>
              <input
                placeholder='Layout image path'
                {...formik.getFieldProps('image')}
                type='text'
                name='image'
                className={clsx(
                  'form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.image && formik.errors.image},
                  {
                    'is-valid': formik.touched.image && !formik.errors.image,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.image && formik.errors.image && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.image}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-2'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>Icon</label>
              <input
                placeholder='Layout icon path'
                {...formik.getFieldProps('icon')}
                type='text'
                name='icon'
                className={clsx(
                  'form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.icon && formik.errors.icon},
                  {
                    'is-valid': formik.touched.icon && !formik.errors.icon,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.icon && formik.errors.icon && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.icon}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='mb-2'>
                <label className='required fw-bold fs-6 me-2 min-w-60px'>Component</label>
                <Select
                  className={clsx(
                    'form-control mb-3 mb-lg-0',
                    {
                      'is-invalid': formik.touched.component_id && formik.errors.component_id,
                    },
                    {
                      'is-valid': formik.touched.component_id && !formik.errors.component_id,
                    }
                  )}
                  name='component_id'
                  onChange={(e) => {
                    formik.setFieldValue('component_id', e?.id)
                  }}
                  options={componentList}
                  getOptionLabel={(option) => option?.title}
                />

                {formik.touched.component_id && formik.errors.component_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.component_id}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='py-5'>
          <button
            type='submit'
            className='btn btn-dark'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched || addLayoutLoading}
          >
            <span className='indicator-label'>Save</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default LayoutAddModal
