import {KTSVG} from '../../../../../../_metronic/helpers'
import {UsersListFilter} from './UsersListFilter'

const UsersListToolbar = () => {
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <UsersListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-sm btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button>
      {/* end::Export */}
    </div>
  )
}

export {UsersListToolbar}
