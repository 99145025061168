import axios from 'axios'
import {useMutation} from 'react-query'
import {URL} from '../../../modules/helpers/api/action'
import {getLocal} from '../../../utils/misc'

const profileData = getLocal('wm-auth-onboard')

const handleEditComponent = async (data: any) => {
  const requestBody = {
    component_id: data?.component_id,
    slug: data?.slug,
    title: data?.title,
    image: data?.image,
    icon: data?.icon,
    data: {
      ...data?.meta,
      config: {
        items_per_page: 10,
        sort: 'created_at',
        order: 'desc',
        cat_id: '',
      },
      sort: `${parseInt(data?.sort)}` || '0',
      setting: {
        slider: data?.slider_settings
          ? {
              dots: false,
              arrow: true,
              autoplay: true,
            }
          : undefined,
        show: data?.slider_settings
          ? {
              mobile_row: 2,
              tablet_row: 3,
              desktop_row: 5,
              no_of_item_show: 10,
            }
          : undefined,
        template: data?.template,
        layout_type: data?.layout_type,
      },
    },
    placement:
      data?.placement && data?.placement?.length > 0
        ? data?.placement?.map((item: any) => item?.value).join(', ')
        : '',
  }
  return axios.post(URL.EDIT_COMPONENT, requestBody, {
    headers: {
      'x-access-token': `${profileData?.user?.access_token}`,
    },
  })
}

export const useUpdateComponent = (onSuccessFunc: any, onErrorFunc: any, queryClient: any) => {
  return useMutation(handleEditComponent, {
    onSuccess: onSuccessFunc,
    onError: onErrorFunc,
    onSettled: () => {
      queryClient.invalidateQueries('create')
    },
  })
}
