import {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import ComponentAddModal from '../../../courier/lists/components/component-modals/ComponentAddModal'
import LayoutAddModal from '../../../courier/lists/components/component-modals/LayoutAddModal'

type Props = {
  refetchFunc: any
  componentList: any[]
}

const ComponentListToolbar = ({refetchFunc = () => null, componentList = []}: Props) => {
  const [showAddModal, setShowAddModal] = useState(false)
  const [showLayoutModal, setShowLayoutModal] = useState(false)
  const openAddComponentModal = () => {
    setShowAddModal(true)
  }

  const openAddLayoutModal = () => {
    setShowLayoutModal(true)
  }

  return (
    <>
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Component</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ComponentAddModal />
        </Modal.Body>
      </Modal>
      <Modal show={showLayoutModal} onHide={() => setShowLayoutModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Layout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LayoutAddModal componentList={componentList} />
        </Modal.Body>
      </Modal>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        <button type='button' className='btn btn-primary mx-2' onClick={openAddComponentModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Add Component
        </button>
        <button type='button' className='btn btn-primary' onClick={openAddLayoutModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Add Layout
        </button>
        <button type='button' className='btn btn-primary mx-2' onClick={refetchFunc}>
          <KTSVG path='/media/icons/duotune/coding/cod005.svg' className='svg-icon-2' />
          Refresh
        </button>
      </div>
    </>
  )
}

export {ComponentListToolbar}
