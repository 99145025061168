import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {QueryClient} from 'react-query'
import Select from 'react-select'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useComponentDelete} from '../../../../editor/api/useComponentDelete'
import {useUpdateComponent} from '../../../../editor/api/useUpdateComponent'

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  slug: Yup.string().required('Slug is required'),
  image: Yup.string().required('Image path is required'),
  icon: Yup.string().required('Icon path is required'),
  placement: Yup.array().required('Placement is required'),
  sort: Yup.string().required('Sort is required'),
  template: Yup.string().required('Template is required'),
  layout_type: Yup.string().required('Layout Type is required'),
  meta: Yup.string().optional(),
  slider_settings: Yup.boolean().required('Slider Settings is required'),
})

const placementOptions = [
  {
    id: 1,
    label: 'Header',
    value: 'header',
  },
  {
    id: 2,
    label: 'Body',
    value: 'body',
  },
  {
    id: 3,
    label: 'Footer',
    value: 'footer',
  },
  {
    id: 4,
    label: 'Sidebar',
    value: 'sidebar',
  },
  {
    id: 5,
    label: 'Page',
    value: 'page',
  },
]

type Props = {
  oldData: any
}

const ComponentEditModal = ({oldData}: Props) => {
  console.log(oldData?.original)
  const queryClient = new QueryClient()
  const [extractedMeta, setExtractedMeta] = useState({})
  useEffect(() => {
    if (oldData?.original?.data) {
      let modifiedMeta = {
        ...oldData?.original?.data,
      }
      delete modifiedMeta['sort']
      delete modifiedMeta['config']
      delete modifiedMeta['setting']
      setExtractedMeta(modifiedMeta)
    }
  }, [])
  const defaultValue = {
    title: oldData?.original?.title || '',
    slug: oldData?.original?.slug || '',
    image: oldData?.original?.image || '',
    icon: oldData?.original?.icon || '',
    placement: oldData?.original?.placement?.split(',') || '',
    sort: oldData?.original?.data?.sort || '',
    template: oldData?.original?.data?.setting?.template || '',
    layout_type: oldData?.original?.data?.setting?.layout_type || '',
    slider_settings: oldData?.original?.data?.setting?.slider ? true : false,
    meta: JSON.stringify(extractedMeta) || '',
  }

  console.log(oldData?.original?.placement?.split(','))

  const {
    mutate: updateComponentMutate,
    isLoading: updateComponentLoading,
    data: updateComponentData,
    error: updateComponentErr,
  } = useUpdateComponent(
    (res: any) => {
      console.log(res)
      if (res?.data?.success) {
        toast.success(res?.data?.message || 'Component Updated', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      } else if (!res?.data?.success) {
        toast.error(res?.data?.message || 'Error Occurred', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    },
    (error: any) => {
      toast.error(error?.data?.message || 'Error Occurred', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    },
    queryClient
  )

  const {
    mutate: componentDelMutate,
    isLoading: compDelLoading,
    data: compDelData,
    error: compDelErr,
  } = useComponentDelete(
    (res: any) => {
      console.log(res)
      if (res?.data?.success) {
        toast.success(res?.data?.message || 'Component Deleted', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      } else if (!res?.data?.success) {
        toast.error(res?.data?.message || 'Error Occurred', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    },
    (error: any) => {
      toast.error(error?.data?.message || 'Error Occurred', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    },
    queryClient
  )

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: defaultValue,
    validationSchema: schema,

    onSubmit: async (values, {setSubmitting}) => {
      let valuesWithId = {
        component_id: oldData?.original?.id,
        ...values,
      }
      setSubmitting(true)
      let metaObject = undefined
      if (values.meta) {
        try {
          metaObject = JSON.parse(values.meta)
          updateComponentMutate({...valuesWithId, meta: metaObject})
        } catch (err) {
          toast.error('Invalid object', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      } else {
        updateComponentMutate(valuesWithId)
      }
    },
  })

  console.log(
    oldData?.original?.placement?.split(',')?.map((item: any) => {
      return {
        label: item,
        value: item,
      }
    })
  )

  return (
    <div>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col'>
            <div className='row'>
              <div className='col'>
                <div className='mb-2'>
                  <label className='required fw-bold fs-6 me-2 min-w-60px'>Title</label>
                  <input
                    placeholder='Component Title'
                    {...formik.getFieldProps('title')}
                    type='text'
                    name='title'
                    className={clsx(
                      'form-control mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.title && formik.errors.title},
                      {
                        'is-valid': formik.touched.title && !formik.errors.title,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col'>
                <div className='mb-2'>
                  <label className='required fw-bold fs-6 me-2 min-w-60px'>Slug</label>
                  <input
                    placeholder='Component slug'
                    {...formik.getFieldProps('slug')}
                    type='text'
                    name='slug'
                    className={clsx(
                      'form-control mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.slug && formik.errors.slug},
                      {
                        'is-valid': formik.touched.slug && !formik.errors.slug,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.slug && formik.errors.slug && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.slug}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>Image</label>
              <input
                placeholder='Component image path'
                {...formik.getFieldProps('image')}
                type='text'
                name='image'
                className={clsx(
                  'form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.image && formik.errors.image},
                  {
                    'is-valid': formik.touched.image && !formik.errors.image,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.image && formik.errors.image && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.image}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-2'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>Icon</label>
              <input
                placeholder='Component icon path'
                {...formik.getFieldProps('icon')}
                type='text'
                name='icon'
                className={clsx(
                  'form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.icon && formik.errors.icon},
                  {
                    'is-valid': formik.touched.icon && !formik.errors.icon,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.icon && formik.errors.icon && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.icon}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='row'>
              <div className='col'>
                <div className='mb-2'>
                  <label className='required fw-bold fs-6 me-2 min-w-60px'>Sort</label>
                  <input
                    placeholder='Component sorting'
                    {...formik.getFieldProps('sort')}
                    type='text'
                    name='sort'
                    className={clsx(
                      'form-control mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.sort && formik.errors.sort},
                      {
                        'is-valid': formik.touched.sort && !formik.errors.sort,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.sort && formik.errors.sort && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.sort}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='mb-2'>
                  <label className='required fw-bold fs-6 me-2 min-w-60px'>Template</label>
                  <input
                    placeholder='Component template'
                    {...formik.getFieldProps('template')}
                    type='text'
                    name='template'
                    className={clsx(
                      'form-control mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.template && formik.errors.template},
                      {
                        'is-valid': formik.touched.template && !formik.errors.template,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.template && formik.errors.template && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.template}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col'>
                <div className='mb-2'>
                  <label className='required fw-bold fs-6 me-2 min-w-60px'>Layout type</label>
                  <input
                    placeholder='Component layout type'
                    {...formik.getFieldProps('layout_type')}
                    type='text'
                    name='layout_type'
                    className={clsx(
                      'form-control mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.layout_type && formik.errors.layout_type},
                      {
                        'is-valid': formik.touched.layout_type && !formik.errors.layout_type,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.layout_type && formik.errors.layout_type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.layout_type}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='mb-2'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>Placement</label>
              <Select
                className={clsx(
                  'form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.placement && formik.errors.placement},
                  {
                    'is-valid': formik.touched.placement && !formik.errors.placement,
                  }
                )}
                name='placement'
                isMulti={true}
                onChange={(e) => formik.setFieldValue('placement', e)}
                // {...formik.getFieldProps('placement')}
                // value={placementOptions}
                options={placementOptions}
                defaultValue={oldData?.original?.placement?.split(',')?.map((item: any) => {
                  return {
                    label: item,
                    value: item,
                  }
                })}
              />

              {formik.touched.placement && formik.errors.placement && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.placement}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='row'>
              <div className='col'>
                <div className='mb-2'>
                  <label className='required fw-bold fs-6 me-2 min-w-60px'>Slider</label>
                  <Select
                    className={clsx(
                      'form-control mb-3 mb-lg-0',
                      {
                        'is-invalid':
                          formik.touched.slider_settings && formik.errors.slider_settings,
                      },
                      {
                        'is-valid':
                          formik.touched.slider_settings && !formik.errors.slider_settings,
                      }
                    )}
                    name='slider_settings'
                    onChange={(e) => formik.setFieldValue('slider_settings', e?.value)}
                    options={[
                      {
                        id: 1,
                        label: 'Enabled',
                        value: true,
                      },
                      {
                        id: 1,
                        label: 'Disabled',
                        value: false,
                      },
                    ]}
                    defaultInputValue={
                      oldData?.original?.data?.setting?.slider ? 'Enabled' : 'Disabled'
                    }
                  />

                  {formik.touched.slider_settings && formik.errors.slider_settings && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.slider_settings}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-2'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>Extra</label>
              <textarea
                placeholder='Enter an object'
                {...formik.getFieldProps('meta')}
                name='meta'
                rows={8}
                cols={50}
                className={clsx(
                  'form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.meta && formik.errors.meta},
                  {
                    'is-valid': formik.touched.meta && !formik.errors.meta,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {/* {formik.touched.meta && formik.errors.meta && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.meta}</span>
              </div>
            </div>
          )} */}
            </div>
          </div>
        </div>

        <div className='d-flex flex-row py-5 w-100'>
          <div className='d-flex justify-content-start'>
            <button
              type='submit'
              className='btn btn-dark'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || updateComponentLoading}
            >
              <span className='indicator-label'>Save</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          <div className='d-flex justify-content-end' style={{marginLeft: 'auto'}}>
            {/* <button
              className='btn btn-danger'
              disabled={formik.isSubmitting || updateComponentLoading || compDelLoading}
              onClick={() => componentDelMutate(oldData?.original?.id)}
            >
              <span className='indicator-label'>Delete</span>
            </button> */}
          </div>
        </div>
      </form>
    </div>
  )
}

export default ComponentEditModal
