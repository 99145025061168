import moment from 'moment'

export const dateReadable = (date, format = 'yyyy-MM-DD HH:mm:s') => {
  return moment(date).format(format) //2023-12-31 23:59:59
}
export const addDate = (cd, n, d) => {
  var future = moment(cd || new Date())
    .add(n, d)
    .format('yyyy-MM-DD HH:mm:s')
  return future
}
export function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}
