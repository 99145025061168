/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { StatisticsWidget5, StatisticsWidget6 } from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch text-white mb-xl-8'
          svgIcon='/media/icons/duotune/general/gen032.svg'
          color='success'
          iconColor='white'
          title='500M$'
          description='Total Revenue'
        />
      </div>

      <div className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch text-white mb-xl-8'
          svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
          color='danger'
          iconColor='white'
          title='+3000'
          description='New Merchant'
        />
      </div>

      <div className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch text-white mb-xl-8'
          svgIcon='/media/icons/duotune/finance/fin006.svg'
          color='primary'
          iconColor='white'
          title='$50,000'
          description='Milestone Reached'
        />
      </div>

      <div className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch text-white mb-5 mb-xl-8'
          svgIcon='/media/icons/duotune/graphs/gra007.svg'
          color='info'
          iconColor='white'
          title='$50,000'
          description='Milestone target'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <StatisticsWidget6
          className='card-xl-stretch mb-xl-8'
          color='success'
          title='8k Goal'
          description='Merchant Goal'
          progress='50%'
        />
      </div>

      <div className='col-xl-4'>
        <StatisticsWidget6
          className='card-xl-stretch mb-xl-8'
          color='warning'
          title='48k Goal'
          description='Finance Goal'
          progress='15%'
        />
      </div>

      <div className='col-xl-4'>
        <StatisticsWidget6
          className='card-xl-stretch mb-xl-8'
          color='primary'
          title='400k Impressions'
          description='Marketing Cost'
          progress='76%'
        />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  let bodyStyles = '';
  bodyStyles += '--kt-toolbar-height: 0px;';
  bodyStyles += '--kt-toolbar-height-tablet-and-mobile:: 0px;';
  document.body.setAttribute('style', bodyStyles);
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      {/* <Toolbar /> */}
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
