import axios from 'axios'
import {useMutation} from 'react-query'
import {URL} from '../../../modules/helpers/api/action'
import {getLocal} from '../../../utils/misc'

const profileData = getLocal('wm-auth-onboard')

const handleAddLayout = async (data: any) => {
  const requestBody = {
    component_id: data?.component_id,
    slug: data?.slug,
    title: data?.title,
    icon: data?.icon,
    image: data?.image,
    template_ids: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
  }
  return axios.post(URL.ADD_LAYOUT, requestBody, {
    headers: {
      'x-access-token': `${profileData?.user?.access_token}`,
    },
  })
}

export const useAddLayout = (onSuccessFunc: any, onErrorFunc: any, queryClient: any) => {
  return useMutation(handleAddLayout, {
    onSuccess: onSuccessFunc,
    onError: onErrorFunc,
    onSettled: () => {
      queryClient.invalidateQueries('create')
    },
  })
}
