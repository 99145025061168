import dayjs from "dayjs";
export const settings = {
  className: "center view",
  // centerMode: true,
  // autoplay: true,
  infinite: false,
  // centerPadding: "60px",
  arrows: true,
  dots: true,
  padding: "20px",
  slidesToShow: 4,
  speed: 500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
  ],
};

export const groupByKey = (array, key) => {
  return array
    .reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
    }, {})
}

export const getLocal = (name) => {
  if (typeof window !== "undefined") {
    if (localStorage === undefined || !localStorage) {
      return;
    }

    const lsValue = localStorage.getItem(name);
    if (!lsValue) {
      return;
    }
    try {
      const auth = JSON.parse(lsValue);
      if (auth) {
        return auth;
      }
    } catch (error) {
      console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
    }
  }
};

export const setLocal = (name, value) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(value);
    localStorage.setItem(name, lsValue);
  } catch (error) {
    console.error(" LOCAL STORAGE SAVE ERROR", error);
  }
};

export const removeLocal = (name) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(name);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export const getTimeFromUnixValue = (time, format = "YYYY-MM-DD hh:mm:ss") => {
  if (!Number(time)) return;

  const a = new Date(time * 1000);

  return dayjs(a).format(format);
};

export const enumFormatter = (enumWord = "") => {
  try {
    let words = enumWord?.includes("_")
      ? enumWord?.toLowerCase()?.split("_")
      : enumWord?.toLowerCase()?.split(" "); //returns array
    for (let i = 0; i < words?.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words?.join(" ");
  } catch (e) {
    return enumWord;
  }
};
