import {UserEditModalForm} from './UserEditModalForm'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponseData} from '../core/QueryResponseProvider'
import {useMemo} from 'react'
import {User} from '../core/_models'

const UserEditModalFormWrapper = () => {
  const {itemIdForUpdate} = useListView()
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])

  let user: any = data.filter((f) => f.id === itemIdForUpdate)

  if (user && user.length > 0) {
    let userData: User = user[0]
    return <UserEditModalForm user={userData} />
  }

  return null
}

export {UserEditModalFormWrapper}
