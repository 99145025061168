// @ts-nocheck
import clsx from 'clsx'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Row} from 'react-table'
import ComponentEditModal from '../../../courier/lists/components/component-modals/ComponentEditModal'
import {User} from '../../core/_models'

type Props = {
  row: Row<User>
}

const CustomRow: FC<Props> = ({row}) => {
  const [showEditModal, setShowEditModal] = useState(false)

  return (
    <>
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Component</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ComponentEditModal oldData={row} />
        </Modal.Body>
      </Modal>
      <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
              style={{cursor: 'pointer'}}
              onClick={() => setShowEditModal(true)}
            >
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>
    </>
  )
}

export {CustomRow}
