import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {useAuth} from '../../modules/auth'
import {queryRequest} from '../../modules/helpers/api'
import {URL} from '../../modules/helpers/api/action'

const editPassSchema = Yup.object().shape({
  current_password: Yup.string().required('Password is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(16, 'Maximum 16 symbols')
    .required('Password is required'),
  confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const Password = () => {
  const {currentUser} = useAuth()
  const [userForEdit] = useState({
    uid: currentUser?.user?.id,
    type: 'password',
    current_password: '',
    password: '',
    confirm_password: 'Banglades',
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editPassSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const res = await queryRequest(URL.USER_UPDATE, values)
        if (res.success && res.status_code === 200) {
          toast.success(res.message)
        } else {
          toast.error(res.message)
        }
        // }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <div className='mw-500px'>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='mb-2'>
          <label className='required fw-bold fs-6 me-2 min-w-60px'>Current Password</label>
          <input
            placeholder='Current Password'
            {...formik.getFieldProps('current_password')}
            type='password'
            name='current_password'
            className={clsx(
              'form-control mb-3 mb-lg-0',
              {'is-invalid': formik.touched.current_password && formik.errors.current_password},
              {
                'is-valid': formik.touched.current_password && !formik.errors.current_password,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting}
          />
          {formik.touched.current_password && formik.errors.current_password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.current_password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='row'>
          <div className='col'>
            <div className='mb-2'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>New Password</label>
              <input
                placeholder='New Password'
                {...formik.getFieldProps('password')}
                type='password'
                name='password'
                className={clsx(
                  'form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col'>
            <div className='mb-2'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>Confirm Password</label>
              <input
                placeholder='Confirm Password'
                {...formik.getFieldProps('confirm_password')}
                type='password'
                name='confirm_password'
                className={clsx(
                  'form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password},
                  {
                    'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.confirm_password && formik.errors.confirm_password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.confirm_password}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='py-5'>
          <button
            type='submit'
            className='btn btn-dark'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Save</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Password
