import { useQuery } from "react-query";
import axios from "axios";
import { URL } from "../../../modules/helpers/api/action";


export const useGetAllComponents = (token: string) => {
  return useQuery(
    ["all-components", token],
    () => {
      return axios.get(
        URL.GET_COMPONENTS,
        {
          headers: {
            'x-access-token': `${token}`,
          },
        }
      );
    },
    {
      enabled: token ? true : false,
    }
  );
};