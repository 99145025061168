/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Merchant Management
          </span>
        </div>
      </div>
      <AsideMenuItem
        to='/merchants'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Merchants'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/courier'
        icon='/media/icons/duotune/ecommerce/ecm006.svg'
        title='Courier'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/users'
        icon='/media/icons/duotune/communication/com014.svg'
        title='Users'
        fontIcon='bi-person'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Account Management
          </span>
        </div>
      </div>
      <AsideMenuItem
        to='/profile'
        icon='/media/icons/duotune/communication/com013.svg'
        title='Profile'
        fontIcon='bi-person'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Editor Management
          </span>
        </div>
      </div>
      <AsideMenuItem
        to='/editor/components'
        icon='/media/icons/duotune/art/art002.svg'
        title='Components'
        fontIcon='bi-layers'
      />
    </>
  )
}
