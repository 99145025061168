import axios from 'axios'
import {useMutation} from 'react-query'
import {URL} from '../../../modules/helpers/api/action'
import {getLocal} from '../../../utils/misc'

const profileData = getLocal('wm-auth-onboard')

const handleDeleteComponent = async (id: any) => {
  const requestBody = {
    component_id: id,
  }
  return axios.post(URL.DELETE_COMPONENT, requestBody, {
    headers: {
      'x-access-token': `${profileData?.user?.access_token}`,
    },
  })
}

export const useComponentDelete = (onSuccessFunc: any, onErrorFunc: any, queryClient: any) => {
  return useMutation(handleDeleteComponent, {
    onSuccess: onSuccessFunc,
    onError: onErrorFunc,
    onSettled: () => {
      queryClient.invalidateQueries('create')
    },
  })
}
