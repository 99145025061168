import axios from 'axios'
import { getAuth, setAuth } from '../../auth'
import {URL} from './action'

const auth = getAuth()

function getLocalAccessToken() {
  return auth && auth.user ? auth.user.access_token : ''
}
function getLocalRefreshToken() {
  return auth && auth.user ? auth.user.refresh_token : ''
}

axios.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken()
    if (token) {
      config.headers['x-access-token'] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  async (res) => {
    // console.log("resdd", res)
    const originalConfig = res.config
    if (res?.data.status_code === 602 && !originalConfig._retry) {
      originalConfig._retry = true
      try {
        const rs = await refreshToken()
        console.log('rs', rs)
        if (rs.data.status_code === 200) {
          const {access_token} = rs.data
          auth.user.access_token = access_token
          setAuth(auth)
          axios.defaults.headers.common['x-access-token'] = access_token
          return axios(originalConfig)
        } else {
          // localStorage.removeItem('user');
          // window.location.href = process.env.REACT_APP_THEME_URL
        }
      } catch (_error) {
        console.log('_error', _error)
        if (_error.response && _error.response.data) {
          return Promise.reject(_error.response.data)
        }
        return Promise.reject(_error)
      }
    } else {
      return res
    }
    // return res;
  },
  async (err) => {
    // console.log("err", err)
    const originalConfig = err.config
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          const rs = await refreshToken()
          const {access_token} = rs.data

          auth.user.access_token = access_token
          setAuth(auth)

          axios.defaults.headers.common['x-access-token'] = access_token
          return axios(originalConfig)
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data)
          }
          return Promise.reject(_error)
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data)
      }
    }
    return Promise.reject(err)
  }
)

function refreshToken() {
  return axios.post(URL.REFRASH_TOKEN, {
    refresh_token: getLocalRefreshToken(),
  })
}

export const uploadImageBase64 = async (image) => {
  // console.log("image",image)
  try {
    const {data: response} = await axios({
      method: 'POST',
      url: URL.UPLOAD_IMAGE_BASE64,
      data: {
        field_name: 'product_gallery',
        image,
      },
    })

    return response
  } catch (err) {
    console.log(err)
    return {
      success: false,
      status_code: 5001,
      message: err.message,
    }
  }
}
export const uploadImageFile = async (file) => {
  try {
    let formData = new FormData()
    formData.append('image', file)
    formData.append('field_name', `product_gallery`)
    const {data: response} = await axios({
      method: 'POST',
      url: URL.UPLOAD_IMAGE_FILE,
      data: formData,
    })

    return response
  } catch (err) {
    return {
      success: false,
      status_code: 5001,
      message: err.message,
    }
  }
}

export const queryRequest = async (url, data) => {
  try {
    const {data: response} = await axios({
      method: 'POST',
      url: url,
      data: data,
    })

    return response
  } catch (err) {
    return {
      success: false,
      status_code: 5001,
      message: err.message,
    }
  }
}
export const getQueryRequest = async (url) => {
  try {
    const {data: response} = await axios({
      method: 'GET',
      url: url,
    })

    return response
  } catch (err) {
    return {
      success: false,
      status_code: 5001,
      message: err.message,
    }
  }
}
