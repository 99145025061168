import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {User} from '../core/_models'
import {queryRequest} from '../../../../modules/helpers/api'
import {URL} from '../../../../modules/helpers/api/action'
import {toast} from 'react-toastify'

type Props = {
  user: User
}

const editUserSchema = Yup.object().shape({
  sid: Yup.string().required('Sid is required'),
  courier_partner_id: Yup.number().required('Courier Partner ID is required'),
  username: Yup.string().required('Courier username is required'),
  password: Yup.string().required('Courier password is required'),
  client_secret: Yup.string().min(35).required('Courier Secret is required'),
  client_id: Yup.number().required('Courier ID is required'),
})

const UserEditModalForm: FC<Props> = ({user}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [userForEdit, setFormEdit] = useState<User>({
    ...user,
  })

  useEffect(() => {
    // if (Number(user.courier_partner_id) === 2) {
    getConfig()
    // }
  }, [user.id])

  const getConfig = async () => {
    const res = await queryRequest(URL.GET_COURIER_CONFIG, {
      sid: user.sid,
      courier_partner_id: user.courier_partner_id,
    })
    console.log(user)
    if (res.success && res.status_code === 200) {
      let data = res.data && res.data.length > 0 ? res.data[0] : {}

      setFormEdit({
        ...user,
        username: data.username,
        client_id: data.client_id,
        client_secret: data.client_secret,
        password: data.PASSWORD,
      })
    }
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    enableReinitialize: true,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const res = await queryRequest(URL.COURIER_CONFIG, values)
        if (res.success && res.status_code === 200) {
          toast.success(res.message)
          cancel(true)
        } else {
          toast.error(res.message)
        }
        // }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div className='d-flex flex-column'>
          <div className='row mb-4 align-items-center'>
            <div className='col-3'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>Username</label>
            </div>
            <div className='col-9'>
              <input
                placeholder='Full name'
                {...formik.getFieldProps('username')}
                type='text'
                readOnly
                name='username'
                className={clsx(
                  'form-control-solid form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.username && formik.errors.username},
                  {
                    'is-valid': formik.touched.username && !formik.errors.username,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.username && formik.errors.username && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.username}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-4 align-items-center'>
            <div className='col-3'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>Password</label>
            </div>
            <div className='col-9'>
              <input
                placeholder='Full name'
                {...formik.getFieldProps('password')}
                type='password'
                readOnly
                name='password'
                className={clsx(
                  'form-control-solid form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-4 align-items-center'>
            <div className='col-3'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>Client Secret</label>
            </div>
            <div className='col-9'>
              <input
                placeholder='Full name'
                {...formik.getFieldProps('client_secret')}
                type='text'
                name='client_secret'
                readOnly
                className={clsx(
                  'form-control-solid form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.client_secret && formik.errors.client_secret},
                  {
                    'is-valid': formik.touched.client_secret && !formik.errors.client_secret,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.client_secret && formik.errors.client_secret && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.client_secret}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-4 align-items-center'>
            <div className='col-3'>
              <label className='required fw-bold fs-6 me-2 min-w-60px'>Client ID</label>
            </div>
            <div className='col-9'>
              <input
                placeholder='Full name'
                {...formik.getFieldProps('client_id')}
                type='text'
                name='client_id'
                readOnly
                className={clsx(
                  'form-control-solid form-control mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.client_id && formik.errors.client_id},
                  {
                    'is-valid': formik.touched.client_id && !formik.errors.client_id,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.client_id && formik.errors.client_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.client_id}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {Number(user.courier_partner_id) === 2 && (
            <div className='row mb-4 align-items-center'>
              <div className='col-3'>
                <label className='required fw-bold fs-6 me-2 min-w-60px'>Token</label>
              </div>
              <div className='col-9'>
                <input
                  placeholder='Full name'
                  {...formik.getFieldProps('token')}
                  type='text'
                  name='token'
                  className={clsx(
                    'form-control mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.token && formik.errors.token},
                    {
                      'is-valid': formik.touched.token && !formik.errors.token,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.token && formik.errors.token && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.token}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            Cancle
          </button>

          <button
            type='submit'
            className='btn btn-dark'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Save</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <UsersListLoading />}
    </>
  )
}

export {UserEditModalForm}
