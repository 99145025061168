import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {useAuth} from '../../modules/auth'
import InfoModal from './modals/infoModal'
import Password from './password'

const ProfileView = () => {
  const {currentUser} = useAuth()
  const [infoModal, setInfoModal] = useState(false)

  return (
    <div className='d-flex flex-column flex-xl-row'>
      <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
        <div className='card mb-5 mb-xl-8'>
          <div className='card-body pt-15 position-relative'>
            <button
              onClick={() => setInfoModal(true)}
              className='btn btn-sm btn-icon btn-light-primary w-25px h-25px position-absolute top-0 end-0 me-4 mt-4'
            >
              <i className='fas fa-pencil-alt fs-4'></i>
            </button>
            <div className='d-flex flex-center flex-column mb-5'>
              <div className='symbol symbol-100px symbol-circle mb-7'>
                <img src={currentUser?.user.avatar || '/media/avatars/300-1.jpg'} alt='image' />
              </div>
              <a href='#' className='fs-3 text-gray-800 text-hover-primary fw-bolder mb-1'>
                {currentUser?.user.username}
              </a>
              <div className='fs-5 fw-bold text-muted mb-6'>{currentUser?.user.name}</div>
            </div>
            <div className='separator separator-dashed mb-3'></div>
            <div id='kt_customer_view_details' className='collapse show'>
              <div className='py-5 fs-6'>
                <div className='badge badge-light-info d-inline'>Premium user</div>
                <div className='fw-bolder mt-5'>Account ID</div>
                <div className='text-gray-600'>ID-{currentUser?.user.id}</div>
                <div className='fw-bolder mt-5'>Primary Email</div>
                <div className='text-gray-600'>
                  <span className='text-gray-600 text-hover-primary'>
                    {currentUser?.user?.email}
                  </span>
                </div>
                <div className='fw-bolder mt-5'>Primary Mobile</div>
                <div className='text-gray-600'>
                  <span className='text-gray-600 text-hover-primary'>
                    {currentUser?.user?.phone}
                  </span>
                </div>
                <div className='fw-bolder mt-5'>Address</div>
                <div className='text-gray-600'>{currentUser?.user.address}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex-lg-row-fluid ms-lg-15'>
        <div className='show-details'>
          <div className='card mb-6 mb-xl-9'>
            <div className='card-header border-0'>
              <div className='card-title'>
                <h2>Password Setting</h2>
              </div>
            </div>
            <div className='card-body py-0'>
              <Password />
            </div>
          </div>
        </div>
      </div>
      <Modal show={infoModal} onHide={() => setInfoModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Information</Modal.Title>
        </Modal.Header>
        <InfoModal setInfoModal={setInfoModal} data={currentUser} />
      </Modal>
    </div>
  )
}

export default ProfileView
