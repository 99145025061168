import axios from 'axios'
import { URL } from '../../helpers/api/action'
import {AuthModel, UserModel} from './_models'

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(URL.LOGIN, {
    email,
    password,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(URL.REFRASH_TOKEN, {
    refresh_token: token,
  })
}
