import {ComponentListSearchComponent} from './ComponentListSearchComponent'
import {ComponentListToolbar} from './ComponentListToolbar'

type Props = {
  refetchFunc: any
  componentList: any[]
}

const ComponentListHeader = ({refetchFunc, componentList}: Props) => {
  return (
    <div className='card-header border-0 pt-6'>
      <ComponentListSearchComponent />
      {/* begin::Card toolbar */}
      <ComponentListToolbar refetchFunc={refetchFunc} componentList={componentList} />
      {/* end::Card toolbar */}
    </div>
  )
}

export default ComponentListHeader
