import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { UsersListHeader } from './components/header/UsersListHeader'
import { UsersTable } from './table/UsersTable'
import { UserEditModal } from './user-edit-modal/UserEditModal'
import { KTCard } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'

const UsersList = () => {
  let bodyStyles = '';
  bodyStyles += '--kt-toolbar-height: 0px;';
  bodyStyles += '--kt-toolbar-height-tablet-and-mobile:: 0px;';
  document.body.setAttribute('style', bodyStyles);

  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <UsersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

const UsersWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PageTitle >Users</PageTitle>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { UsersWrapper }
