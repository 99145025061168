import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getLocal} from '../../../utils/misc'
import ComponentListHeader from '../../users/components/header/ComponentListHeader'
import {ListViewProvider} from '../../users/core/ListViewProvider'
import {QueryRequestProvider} from '../../users/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../users/core/QueryResponseProvider'
import ComponentsTable from '../../users/table/ComponentsTable'
import {useGetAllComponents} from '../api/useGetAllComponents'

const ComponentList = () => {
  let bodyStyles = ''
  bodyStyles += '--kt-toolbar-height: 0px;'
  bodyStyles += '--kt-toolbar-height-tablet-and-mobile:: 0px;'
  document.body.setAttribute('style', bodyStyles)
  const profileData = getLocal('wm-auth-onboard')
  const {
    isLoading,
    data: componentsData,
    refetch,
  } = useGetAllComponents(profileData?.user?.access_token)
  return (
    <>
      <KTCard>
        <ComponentListHeader refetchFunc={refetch} componentList={componentsData?.data?.data} />
        <ComponentsTable data={componentsData?.data?.data} isLoading={isLoading} />
      </KTCard>
    </>
  )
}

const EditorComponents = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PageTitle>Editor Components</PageTitle>
        <ComponentList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EditorComponents}
