import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {CourierWrapper} from './lists'

const CourierPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='index'
        element={
          <>
            <PageTitle>Requested for Courier</PageTitle>
            <CourierWrapper />
          </>
        }
      />
      <Route index element={<Navigate to='/courier/index' />} />
    </Route>
  </Routes>
)

export default CourierPage
